<template>
    <div
        class="meeting-card"
        :class="meetingStatus.replace(' ', '').toLowerCase()"
        v-if="!hideSelfCancelled"
    >
        <div @click="viewMeeting()">
            <div class="flex top-row">
                <div class="time">
                    {{ meeting.start_time | formatTime("hh:mma") }} -
                    {{ meeting.end_time | formatTime("hh:mma") }}
                </div>
                <div class="pill">
                    {{ meetingStatus }}
                </div>
            </div>

            <div class="attende-name">{{ otherAttendee.attendee.fullName }}</div>
            <div class="attende-slug">{{ otherAttendee.attendee.positionString }}</div>
        </div>

        <WayFinding
            class="location-wrapper"
            v-if="meeting.location.template_id && meeting.location.module_id"
            :template-id="meeting.location.template_id"
            :module-id="meeting.location.module_id"
        >
            <div class="icon">
                <v-icon size="17">mdi-map-marker</v-icon>
            </div>
            <div class="location">{{ meeting.location.location_name }}</div>
        </WayFinding>
        <div v-else class="location-wrapper" @click="viewMeeting()">
            <div class="icon">
                <v-icon size="17">mdi-map-marker</v-icon>
            </div>
            <div class="location">{{ meeting.location.location_name }}</div>
        </div>

        <div class="select" @click="viewMeeting()">
            <svg
                version="1.2"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                overflow="visible"
                preserveAspectRatio="none"
                viewBox="0 0 24 24"
                width="24"
                height="24"
            >
                <g>
                    <path
                        xmlns:default="http://www.w3.org/2000/svg"
                        d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
                        vector-effect="non-scaling-stroke"
                    />
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
import WayFinding from "../../components/WayFinding.vue";

export default {
    name: "MeetingCard",
    components: {
        WayFinding,
    },
    props: {
        meeting: Object,
    },
    data() {
        return {
            meetingStatus: String,
            otherAttendee: Object,
            myself: Object,
            showAlert: false,
            hideSelfCancelled: false,
        };
    },
    methods: {
        viewMeeting() {
            let queryParams = new URLSearchParams({
                ...this.$route.query,
            });

            let path = `/dashboard/meeting/${this.meeting.meeting_id}`;
            let title = `Meeting with ${this.otherAttendee.attendee.first_name}`;
            if (this.$root.isApp) {
                this.$root.entegyCallbacks.changeRoute(path, title, queryParams);
                return;
            }

            this.$router.push(path);
        },
        setAttendees: function () {
            let myProfileId = this.$root.attendee.profile_id;
            let vm = this;

            let participants = JSON.parse(JSON.stringify(this.meeting.participants));

            participants.forEach(function (participant) {
                if (participant.attendee.profile_id !== myProfileId) {
                    participant.attendee.fullName =
                        participant.attendee.first_name + " " + participant.attendee.last_name;
                    participant.attendee.positionString = vm.getPositionString(
                        participant.attendee
                    );
                    vm.otherAttendee = participant;
                } else {
                    vm.myself = participant;
                }
            });
        },
        setMeetingStatus: function () {
            let status = "";

            // Invitation sent
            if (this.myself.has_accepted && !this.otherAttendee.has_accepted) {
                status = "Invitation Sent";
                this.showAlert = false;
            }

            // Confirmed
            if (this.myself.has_accepted && this.otherAttendee.has_accepted) {
                status = "Confirmed";
                this.showAlert = false;
            }

            // Response needed
            // either you're invited
            if (
                !this.myself.is_inviter &&
                !this.myself.has_accepted &&
                this.otherAttendee.has_accepted
            ) {
                status = "Response Needed";
                this.showAlert = true;
            }

            // host and other attendee has accepted but you haven't
            // ie. you created the meeting, and the other attendee made a change
            if (
                this.myself.is_inviter &&
                !this.myself.has_accepted &&
                this.otherAttendee.has_accepted
            ) {
                status = "Response Needed";
                this.showAlert = true;
            }

            // cancelled
            if (this.meeting.is_cancelled) {
                status = "Cancelled";
                this.showAlert = false;

                if (this.myself.has_cancelled) {
                    this.hideSelfCancelled = true;
                }
            }

            this.meetingStatus = status;
        },
        getPositionString(attendee) {
            let returnString = "";
            if (attendee.position) {
                returnString += attendee.position;
            }

            if (attendee.organisation) {
                if (returnString.length > 0) {
                    returnString += ", ";
                }

                returnString += attendee.organisation;
            }

            return returnString;
        },
    },
    beforeMount() {
        this.setAttendees();
        this.setMeetingStatus();
    },
};
</script>

<style lang="scss">
@import "src/assets/_index.scss";

.meeting-card {
    position: relative;
    margin-top: $medium;
    background: #dadada;
    padding: $medium;
    border-radius: $small;
    border: 1.5px solid transparent;

    .time,
    .attende-slug,
    .attende-name,
    .location {
        color: #333333;
    }

    .select svg {
        fill: #333333 !important;
    }

    .location-wrapper {
        .icon i {
            color: #333333 !important;
        }
    }

    &.invitationsent {
        border-color: #2c2c2c;
        background: white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
        & .pill {
            color: #2c2c2c;
            background: white;
            border: 1px solid #2c2c2c;
        }
    }

    &.responseneeded {
        border-color: #6a68ca;
        background: #bbbadf;
        box-shadow: 0 1px 3px rgba(211, 47, 47, 0.1);
        & .pill {
            color: #5e5cbb;
            background: #acabdb;
            border: 1px solid #6a68ca;
        }
    }

    &.confirmed {
        border-color: #559249;
        background: #e0f3e1;
        box-shadow: 0 1px 3px rgba(106, 104, 202, 0.15);
        & .pill {
            color: #559249;
            background: #ceebd0;
            border: 1px solid #559249;
        }
    }

    &.cancelled {
        border-color: #131313;
        background: #a7a7a7;
        border-style: dashed;
        opacity: 0.6;

        & .pill {
            color: #131313;
            background: #a7a7a7;
            border: 1px solid #131313;
        }

        & .time {
            opacity: 1;
        }
    }

    &.hidden {
        display: none;
    }

    &:first-child {
        margin-top: $large;
    }

    .top-row {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
    }

    .time {
        font-size: 12px;
        font-weight: bold;
        opacity: 0.6;
    }

    .pill {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
        padding: 3px 6px;
        border-radius: 5px;
        font-weight: bold;
        .alert {
            width: 6px;
            height: 6px;
            background: #ff0d00;
            border-radius: 50%;
            margin-right: 4px;
        }
    }

    .attende-name {
        font-size: 18px;
        font-weight: bold;
        margin-top: $small;
    }

    .attende-slug,
    .location {
        font-size: 14px;
    }

    .location-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-top: $medium;

        .icon {
            margin-right: $small;
            color: #121212;
            opacity: 0.4;
        }
    }
    .select {
        position: absolute;
        bottom: $medium;
        right: $medium;
        height: 24px;
        color: #121212;
        opacity: 0.5;
    }
}
</style>
