<template>
    <div class="session-card" @click="toggleDelete" v-if="!isDeleted">
        <div class="flex top-row">
            <div class="time">
                {{ meeting.start_time | formatTime("hh:mma") }} -
                {{ meeting.end_time | formatTime("hh:mma") }}
            </div>
            <div class="pill">
                {{ meeting.session_name }}
            </div>
            <div v-if="showDelete" class="delete" @click="deleteSession">Delete Block</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SessionCard",
    props: {
        meeting: Object,
    },
    data() {
        return {
            showDelete: false,
            isDeleted: false,
            loading: false,
            failed: false,
        };
    },
    methods: {
        toggleDelete: function () {
            this.showDelete = !this.showDelete;
        },
        deleteSession: async function () {
            if (this.loading) {
                return false;
            }

            this.loading = true;

            var axios = require("axios");

            try {
                let payload = {
                    session_id: this.meeting.session_id,
                };
                var response = await axios.post(
                    this.$root.baseApi + "/attendee/session/delete",
                    payload,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + this.$root.token,
                        },
                    }
                );

                if (response.status === 200) {
                    this.isDeleted = true;
                } else {
                    this.failed = true;
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                this.failed = true;
            }
        },
    },
};
</script>

<style lang="scss">
@import "src/assets/_index.scss";

.session-card {
    position: relative;
    margin-top: $medium;
    background: #dadada;
    padding: $medium;
    border-radius: $small;

    .delete {
        background: #a31008;
        padding: 6px 10px;
        color: white;
        border-radius: 10px;
        font-size: 1rem;
    }

    &.confirmed {
        color: white;
        background: #333333;

        .time,
        .attende-slug,
        .attende-name,
        .location {
            color: white;
        }

        .select svg {
            fill: white !important;
        }

        .location-wrapper {
            .icon i {
                color: white !important;
            }
        }
    }

    &.hidden {
        display: none;
    }

    &:first-child {
        margin-top: $large;
    }

    .top-row {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
    }

    .time {
        font-size: 12px;
        font-weight: bold;
        opacity: 0.6;
    }

    .pill {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 11px;
        padding: 3px 5px;
        background: #fff;
        border-radius: 5px;
        .alert {
            width: 6px;
            height: 6px;
            background: #ff0d00;
            border-radius: 50%;
            margin-right: 4px;
        }
    }

    .attende-name {
        font-size: 20px;
        font-weight: bold;
        margin-top: $small;
    }

    .attende-slug,
    .location {
        font-size: 14px;
    }

    .location-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-top: $medium;

        .icon {
            margin-right: $small;
            color: #121212;
            opacity: 0.4;
        }
    }
    .select {
        position: absolute;
        bottom: $medium;
        right: $medium;
        height: 24px;
        color: #121212;
        opacity: 0.5;
    }
}
</style>
